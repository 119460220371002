import jwt from 'jsonwebtoken';
import cyptoJS  from 'crypto-js';
import store from '../store/modules/base.module';

export function encode(string){
    const jwtEncode =jwt.sign(string, 'laravelvueloginencript20200725');
    return jwtEncode;
}
export function decode(enString){
    const jwtDecode = jwt.decode(enString);
    return jwtDecode;
}

export function encryptId(id){
    const str = id.toString();

    return str.toString(35);
}
export function decryptId(id){
    return Number.parseInt(id, 35);
}

// Encrypt
export function cyEncrypt(str){
    const code = store.state.cyKey;
    return cyptoJS.AES.encrypt(str.toString(),  code).toString();
}
// Decrypt
export function cyDecrypt(cipherText){
    const code = store.state.cyKey;
    const bytes  = cyptoJS.AES.decrypt(cipherText, code);

    return bytes.toString(cyptoJS.enc.Utf8);
}

